import { EpisodeTextEntityType } from '../types/types';
import { EpisodeTextImportNode } from './EpisodeTextImportNode';
import { EpisodeTextImportStep } from './EpisodeTextImportStep';

export class EpisodeTextImport {
  public nodes: EpisodeTextImportNode[];
  private currentNodeIndex = -1;
  constructor() {
    this.nodes = [];
  }

  public addNode(node: EpisodeTextImportNode) {
    this.nodes.push(node);
    this.currentNodeIndex = this.nodes.length - 1;
  }

  public addStep(step: EpisodeTextImportStep) {
    this.nodes[this.currentNodeIndex]?.addStep(step);
  }

  public getEpisodeSyntaxText() {
    return this.nodes.map((node) => node.getSyntaxText()).join('\n');
  }

  public createTable(
    nodeNamesInEpisode: string[] = [],
    characterNamesInEpisode: string[] = [],
    locationsNamesInEpisode: string[] = [],
  ) {
    const newTableData = [];
    const nodeNamesInImport = this.nodes.map((el) => el.title);

    for (let nodeIndex = 0; nodeIndex < this.nodes.length; nodeIndex++) {
      const node = this.nodes[nodeIndex];

      const nodeErrors = node.validate({ nodeNamesInImport, nodeNamesInEpisode, locationsNamesInEpisode });

      const nodeData = {
        id: node.id,
        nodeOrSpeaker: node.title,
        text: node.text,
        description: node.description,
        type: EpisodeTextEntityType.Node,
        errors: nodeErrors,
        commands: node.commands,
      };

      const stepsData = node.steps.map((step) => {
        const isLastStep = node.lastStep?.id === step.id;
        const stepErrors = step.validate({
          nodeNamesInImport,
          characterNamesInEpisode,
        });

        return {
          id: step.id,
          nodeOrSpeaker: step.speakerName,
          text: step.speakerText,
          description: step.description,
          type: step.type,
          isLastStep,
          errors: stepErrors,
          commands: step.commands,
        };
      });

      newTableData.push(nodeData, ...stepsData);
    }

    return newTableData;
  }
}
