import { AxiosResponse } from 'axios';
import { api } from '../../components/api';
import { MemoryDTO } from '../../components/pages/Book/MemoryBank/memoryBankTypes';
import { MemoryBankGetResponse, MemoryBankPostRequest, MemoryBankPostResponse } from './types';

export class MemoryBankService {
  public convertMemoryToPostRequestData(memory: MemoryDTO) : MemoryBankPostRequest {
    return {
      name: memory.name,
      type: memory.type,
      defaultValue: memory.defaultValue,
      showIn: memory.showIn,
      displayName: memory.displayName,
      icon: memory.icon,
      defaultChangeDescription: memory.defaultChangeDescription,
    };
  }

  public async fetchMemoryBank(bookId: number | string) : Promise<MemoryDTO[]> {
    const response: AxiosResponse<MemoryBankGetResponse> = await api.get(`/v1/books/${bookId}/variables/`);
    return response.data.variables.map((memoryDTO: MemoryDTO) => ({
      id: memoryDTO.id,
      type: memoryDTO.type,
      name: memoryDTO.name,
      bookId: memoryDTO.bookId,
      defaultValue: memoryDTO.defaultValue ?? '',
      displayName: memoryDTO.displayName ?? '',
      defaultChangeDescription: memoryDTO.defaultChangeDescription ?? '',
      icon: memoryDTO.icon ?? '',
      showIn: memoryDTO.showIn ?? [],
    }));
  }

  public async createMemory(bookId: number, memory: MemoryBankPostRequest) : Promise<MemoryDTO> {
    const response: AxiosResponse<MemoryBankPostResponse> = await api.post(`/v1/books/${bookId}/variables/`, memory);
    return response.data.variable;
  }

  public async updateMemory(memory: MemoryDTO) : Promise<MemoryDTO> {
    const requestData: MemoryBankPostRequest = this.convertMemoryToPostRequestData(memory);
    const response: AxiosResponse<MemoryBankPostResponse> = await api.put(`/v1/books/variables/${memory.id}`, requestData);
    return response.data.variable;
  }

  public async deleteMemory(memory: MemoryDTO) : Promise<void> {
    await api.delete(`/v1/books/variables/${memory.id}`);
  }
}
